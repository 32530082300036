import "babel-polyfill" 
import "core-js/stable";
import "regenerator-runtime/runtime";
import "@/utils/babelAdd.js"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import http from './components/api/http.js'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import store from "@/store/store.js"
import VueCookies from 'vue-cookies'
import 'element-plus/dist/index.css'
import './assets/css/style.css'
import './assets/css/mobile.css'
import './assets/icon/iconfont.css'
import 'windi.css'

import "@/utils/newlocalStorage"
import svgIcon from './icons/index.js'
import '../src/icons/iconfont/iconfont.css'
import 'vant/lib/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {  Area ,showConfirmDialog ,DropdownMenu, DropdownItem,Cell,Loading,NavBar,Form,Checkbox, CheckboxGroup,DatePicker, CellGroup ,Picker,PickerGroup ,BackTop,Search,Popup,Icon,Dialog,Badge, Overlay,Tab, Tabs, Stepper,Divider,RadioGroup, Radio,Cascader,Field , DatetimePicker } from 'vant';
// test

const app = createApp(App)
app.use(router)
app.use(svgIcon)
app.use(Form )
app.use(Picker )
app.use(PickerGroup )
app.use(CellGroup )
app.use(Cascader )
app.use(Search )
app.use(Field )
app.use(RadioGroup)
app.use(Radio )
app.use(Divider)
app.use(DropdownMenu);
app.use(Area);
app.use( Stepper );
app.use(Checkbox);
app.use(CheckboxGroup);
app.use(DatePicker);
app.use(DatetimePicker);
app.use(DropdownItem);
app.use(Tab,);
app.use(Tabs);
app.use(Dialog);
app.use(Cell);
app.use(Badge);
app.use(Overlay);
app.use(BackTop);
app.use(Loading);
app.use(Icon);
app.use(NavBar);
app.use(Popup );
app.use(ElementPlus, {locale})
app.use(store)
app.use(VueCookies)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}//el-icon引入
app.mount('#app')
app.config.globalProperties.$http = http;
// 配置全局过滤器
app.config.globalProperties.$filters = {
  //formatTime过滤器的名称
  fileUrl(url) {
    if (!url){
      return ''
    }else if (/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/.test(url)) {
      return url
    } else {
      return process.env.VUE_APP_STATIC_DIR + url

    }
  },
};

app.config.warnHandler = () => null
/*const app = new Vue({
 router,
 render: h => h(App),
 mounted () {
// document.dispatchEvent(new Event('render-event'))
 document.dispatchEvent(new Event('custom-render-trigger'))
 }
});

app.use(router).use(ElementPlus, {locale})
    .use(store)
    .use(VueCookies)
    .$mount('#app')
//
app.config.globalProperties.$http = http;*/
