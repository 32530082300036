<template>
	<div class="asyncLogin">
		<div class="loginBox">
			<a href="javascript:;" class="closeBtn" @click="closeLogin()"></a>
			<div class="regLoginForm">
				<el-form :model="LoginForm" :rules="rulesLogin" ref="LoginForm">
					<div class="label">欢迎登录贸友圈&赏聘网</div>
					<el-form-item  class="inputList">
						<el-input v-model.trim="LoginForm.mobile" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item prop="password" class="inputList">
						<el-input type="password" v-model="LoginForm.password" show-password placeholder="请输入密码"
							@keyup.enter.native="submitForm('LoginForm')">
						</el-input>
					</el-form-item>
					<!-- <el-form-item prop="protocol" class="protocol">
						<el-checkbox v-model="LoginForm.protocol" checked="checked" name="protocol" label="已阅读并同意">
						</el-checkbox>
						<a href="">《用户协议》</a><a href="">《隐私政策》</a>
					</el-form-item> -->
					<div class="button">
						<input type="button" value="登 录" @click="submitForm('LoginForm')" class="loginBtn">
					</div>
					<div class="loginOther" @click="closeLogin">
						<a href="/register"  class="left">立即注册</a>
						<a href="/forget" class="right">忘记密码？</a>
					</div>
				</el-form>
			</div>
			<!-- <div class="clear"></div>
			<div class="separatorLine"></div>
			 <div class="otherWays">
				其他方式登录：
				<a href="javascript:;"><img src="@/assets/images/login-wechat.png" alt="微信登录"></a>
				<a href="javascript:;"><img src="@/assets/images/login_qq.png" alt="QQ登录"></a>
			</div> -->
		</div>
	</div>
</template>

<script>

	export default {
		data() {
			var mobileReg = /^1([3|4|5|6|7|8|9])\d{9}$/;
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入手机号码'));
				} else if (!mobileReg.test(value)) {
					callback(new Error('手机号码格式错误'));
				} else {
					callback();
				}
			};
			var validatePassword = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else {
					callback();
				}
			};
			return {
				LoginForm: {
					mobile: '',
					password: '',
					// protocol: []
				},
				rulesLogin: {
					mobile: [{
						validator: validatePhone,
						trigger: 'blur'
					}],
					password: [{
						validator: validatePassword,
						trigger: 'blur'
					}],
					// protocol: [{
					// 	required: true,
					// 	message: '请同意用户协议',
					// 	trigger: 'change'
					// }]
				}
			};
		},
		mounted() {

			// console.log(11)
		},
		methods: {
			getMsg(msg) {
				this.LoginForm.mobile = msg
			},
			submitForm(LoginForm) {
				
				this.$refs[LoginForm].validate((valid) => {
					if (valid) {
						this.$http.post('/api/login/login', {
							mobile: this.LoginForm.mobile,
							password: this.LoginForm.password
						}).then(res => {
							if (res.data.code == 200) {
								const data = res.data.data
								// this.$store.commit('setToken', data.token)
								// this.$store.commit('setIsLogin', true)
								// this.$store.commit('setUserInfo', data.user)
								/**
								 * 直接存localStorage
								 */
								 let time= process.env.VUE_APP_ENV == "production" ? 60*60*24*5 : 60*60*24*5
								 data.user.password=''
								 localStorage.setItem("store", JSON.stringify( {
									token:data.token,
									isLogin:true,
									userInfo:data.user
								}),time)
								this.$emit('closeShow', 'close')
								
								// window.localStorage.setItem('tm-store1',JSON.stringify(this.$store.state))
								window.location.reload()
							} else {
								this.$message.closeAll();
								this.$message.error(res.data.msg);
							}
						})
					}
				});
			},
			closeLogin() {
				this.$emit('closeShow', 'close')
			}
		}
	}
</script>
