import Vuex from "vuex";

import newLocalStorage from "@/utils/newlocalStorage"

import createPersistedState from "vuex-persistedstate";
const store = new Vuex.Store({
  state: {
    token:localStorage.getItem("store") ?  JSON.parse(localStorage.getItem("store")).token : '',
    isLogin: localStorage.getItem("store") ? JSON.parse(localStorage.getItem("store")).isLogin : false,
    userInfo: localStorage.getItem("store") ? JSON.parse(localStorage.getItem("store")).userInfo : {},
    message: {},
    shareProduct: {},
    indexLogin:false,
    invitedId:"",
    ArticleContent:''
  },
 
  mutations: {
    setToken(state, token) {
       
      state.token = token;
    },  
    delToken(state) {
      localStorage.clear()
      state.token=''
      state.isLogin=false
      state.userInfo={}
    },
    setIsLogin(state, isLogin) {
    
      // let store= JSON.parse(localStorage.getItem("store"))
      state.isLogin=JSON.parse(localStorage.getItem("store")).isLogin 
      state.userInfo= JSON.parse(localStorage.getItem("store")).userInfo 
      state.token=JSON.parse(localStorage.getItem("store")).token 
    },
    ArticleContent(state,Content){
      console.log(Content,'Content')
      // this.ArticleContent=Content
      sessionStorage.setItem("ArticleContent",JSON.stringify(Content))
    },
    setUserInfo(state, userInfo) {
      /**
       * 非法改值
       */
      // console.log(userInfo,'--------------',state.userInfo)
    // userInfo.password = ""; //密码不要显示在前端
    
    // state.userInfo = userInfo;
    },
    setInfo(state,userInfo){

      let store=JSON.parse(localStorage.getItem("store"))
      store.userInfo.email=userInfo.email
      store.userInfo.username=userInfo.username
      store.userInfo.industryId=userInfo.industryId
      localStorage.setItem("store",JSON.stringify({
          isLogin:store.isLogin,
          token:store.token,
          userInfo:store.userInfo
      }) )

    },
    setMessageId(state, message) {
      state.message = message;
    },  
    setShareProdut(state, shareProduct) {
      state.shareProduct = shareProduct;
    },
    indexLogin(state, staute){
      state.indexLogin=staute
    },
   
  },
 
 
});
// console.log(store.state)

export default store;
